<template>
	<div class="b2c-page-bg">
			<div class="b2c-pc-header mb-5" v-if="!PaymentResponse && isRePayment">
				<a @click="backButton">
					<h2>
						<feather-icon size="24" icon="ChevronLeftIcon"/> Thông tin & tính phí
					</h2>
				</a>
			</div>
			<div v-if="!PaymentResponse  ">
				<div class="mb-5" v-if="isRePayment">
					<PjiCoStep :currentStep="currentStep"></PjiCoStep>
				</div>
				<div v-if="currentStep==1">
					<div class="container w-50 mb-3">
						<div class="b2c-pc-header">
							<h2 class="pl-0">THÔNG TIN NGƯỜI MUA</h2>
						</div>
					</div>
					<validation-observer ref="infoValid">
						<div class="container w-50">
							<b-row class="align-items-center container-body">
								<b-col col="4" class="mx-100">
									<p style="font-weight: 500; font-size: 16px;" class="mb-1">Họ tên <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|min:3">
										<b-form-input class="px-3 mb-2" v-model="FullName" placeholder="Họ và tên" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập họ và tên</small>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row class="align-items-center container-body">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">Số điện thoại <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|length:10">
										<b-form-input class="px-3 mb-2" type="number" :formatter="formatPhone" v-model="Phone" placeholder="Số điện thoại liên hệ" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập số điện thoại liên hệ</small>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row class="align-items-center container-body">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">Địa chỉ <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|min:3">
										<b-form-input class="px-3 mb-2" v-model="Address" placeholder="Địa chỉ" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập địa chỉ</small>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row class="align-items-center container-body">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">Email <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|email">
										<b-form-input class="px-3 mb-2" v-model="Email" placeholder="Email" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập email</small>
									</validation-provider>
								</b-col>
							</b-row>
						</div>
						<div class="container w-50 mb-3">
							<div class="b2c-pc-header">
								<h2 class="pl-0">THÔNG TIN XE MÁY</h2>
							</div>
						</div>
						<div class="container w-50">
							<b-row class="align-items-center container-body">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">Tên chủ xe <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|min:3">
										<b-form-input class="px-3 mb-2" v-model="custName" placeholder="Tên chủ xe" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập tên chủ xe</small>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row class="align-items-center container-body">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">CCCD/CMND <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|min:9">
										<b-form-input class="px-3 mb-2" :formatter="formatCustID" type="number" v-model="custId" placeholder="CCCD/CMND" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập CCCD/CMND</small>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row class="align-items-center container-body">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">Địa chỉ <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|min:3">
										<b-form-input class="px-3 mb-2" v-model="custAddress" placeholder="Địa chỉ" :state="errors.length > 0 ? false : null"/>
										<small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập địa chỉ</small>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row class="align-items-center container-body mb-3">
								<b-col cols="4" class="mx-100">
									<p class="mb-1" style="font-weight: 500; font-size: 16px;">Loại xe <span style="color: red">(*)</span></p>
								</b-col>
								<b-col cols="8" class="mx-100">
									<validation-provider #default="{ errors }" rules="required|min:3">
										<v-select label="value" class="mb-2"
															:state="errors.length > 0 ? false : null"
															placeholder="Loại xe"
															:reduce="y => y"
															:options="Motor"
															:class="errors.length > 0 ? 'is-invalid' : ''" v-model="pickedMotor"></v-select>
										<small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập loại xe</small>
									</validation-provider>
								</b-col>
							</b-row>
              <b-row class="align-items-center container-body mb-1">
                <b-col cols="4" class="mx-100">
                  <p class="mb-1" style="font-weight: 500; font-size: 16px;">Số khung <span style="color: red">(*)</span></p>
                </b-col>
                <b-col cols="8" class="mx-100">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input type="text" class="px-3" @change="HandleChassisNoChange" v-model="ChassisNo"  placeholder="Số khung " maxlength="17" :state="errors.length>0 ? false : null"/>
                    <small class="fs-error-txt" v-if="errors.length>0">Vui lòng nhập số khung</small>
                  </validation-provider>
                </b-col>
              </b-row>
						</div>
					</validation-observer>
					<div class="container w-50">
            <b-row class="align-items-center container-body mb-1">
              <b-col cols="4" class="mx-100">
                <p class="mb-1" style="font-weight: 500; font-size: 16px;">Biển số xe</p>
              </b-col>
              <b-col cols="8" class="mx-100">
                <validation-provider #default="{ errors }" rules="required|min:3">
                  <b-form-input class="px-3" v-model="RegnNo" placeholder="Biển số" />
<!--                  <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập biển số xe</small>-->
                </validation-provider>
              </b-col>
            </b-row>
						<b-row class="align-items-center container-body mb-5">
							<b-col cols="4" class="mx-100">
								<p class="mb-1" style="font-weight: 500; font-size: 16px;">Số máy</p>
							</b-col>
							<b-col cols="8" class="mx-100">
								<validation-provider #default="{ errors }" rules="required|min:3">
									<b-form-input class="px-3" v-model="EngineNo" placeholder="Số máy"/>
									<!-- <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập địa chỉ</small> -->
								</validation-provider>
							</b-col>
						</b-row>
						<b-row class="align-items-center container-body mb-5">
							<b-col>
								<p style="font-weight: 400;">Bằng việc nhấp chuột vào nút <b>” Tính phí “</b>, Bạn xác nhận rằng đã đọc và đồng ý với <a @click="$bvModal.show('modal-Privacy-Policy')"><span style="color: red; text-decoration: underline; cursor: pointer;"><b>Chính sách quyền riêng tư </b></span></a>của iTel.</p>
							</b-col>
						</b-row>
						<b-row class="align-items-center">
							<button type="button" @click="onCharge" class="btn b2c-search-button-red b2c-search-button-red-ml ml-1">Tính phí</button>
						</b-row>
					</div>
				</div>
				<div v-else-if="currentStep==2">
					<div class="d-flex">
						<div class="col-12 col-sm-12 col-md-10">
							<div class="container w-50 mb-3 p-0">
								<div class="b2c-pc-header mb-3">
									<h2 class="pl-0">CHỌN GÓI BẢO HIỂM VÀ TÍNH PHÍ</h2>
								</div>
								<b-row class="align-items-center container-body mb-5">
									<b-col cols="3" class="mx-100">
										<p style="font-weight: 500; font-size: 16px;" class="mb-1">Thời gian bảo hiểm</p>
									</b-col>
									<b-col cols="9" class="mx-100">
										<b-form-input class="px-3" v-model="duration" disabled placeholder="1 Năm"/>
									</b-col>
								</b-row>
								<validation-observer ref="infoValid">
									<validation-provider #default="{ errors }" rules="required|min:3">
										<b-row class="mb-5 container-body">
                      <b-col cols="12"  class="col-lg-6 d-flex container-body justify-content-between align-items-baseline mb-1">
												<p style="font-weight: 500; font-size: 16px;" class="mb-1">Ngày bắt đầu <span style="color: red">(*)</span></p>
												<date-picker @change="onCharge()" class="mx-100"  format="DD/MM/YYYY" :state="errors.length > 0 ? false : null" v-model="startdate" valueType="format" placeholder="dd/mm/yyyy"></date-picker>
											</b-col>
                      <b-col cols="12" class="col-lg-6 d-flex container-body justify-content-between align-items-baseline">
												<p style="font-weight: 500; font-size: 16px;" class="mb-1 myl-0">Ngày kết thúc</p>
												<date-picker class="mx-100" format="DD/MM/YYYY" disabled v-model="enddate" valueType="format" placeholder="dd/mm/yyyy"></date-picker>
											</b-col>
											<small class="ml-5 fs-error-txt" v-if="errors.length > 0">Vui lòng nhập thời gian bảo hiểm</small>
										</b-row>
									</validation-provider>
								</validation-observer>
								<b-row class="align-items-center container-body mb-3">
									<b-col cols="12" class="d-flex">
										<b-form-checkbox @change="onCharge()" v-model="isActive" />
										<p class="ml-2" style="font-weight: 400;">Mua bảo hiểm tai nạn người ngồi trên xe</p>
									</b-col>
								</b-row>
								<b-row class="align-items-center container-body mb-3" v-if="isActive" >
									<b-col cols="3" class="mx-100">
										<p style="font-weight: 500; font-size: 16px;" class="mb-1">Số tiền bảo hiểm</p>
									</b-col>
									<b-col cols="9" class="mx-100">
										<validation-provider #default="{ errors }" rules="required|min:3">
											<b-form-input class="px-3 mxb-5" disabled placeholder="10.000.000đ"/>
											<!-- <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập thời gian bảo hiểm</small> -->
										</validation-provider>
									</b-col>
								</b-row>
								<b-row class="align-items-center mb-3"  >
									<b-col cols="12" class="content-mt" :class="!isActive?'content-mt-mb__Active':''">
										<p style="font-weight: 600; font-size: 16px;">Lưu ý</p>
										<p style="font-weight: 400;">1/ Đây là hình thức mua bảo hiểm online, Pjico sẽ cấp giấy chứng nhận BH TNDS điện tử về email bạn cung cấp. Bạn có thể liên hệ Hotline PJICO 1900 54 54 55 (1.000đ/phút) để yêu cầu bản cứng nếu cần.</p>
										<p style="font-weight: 400;">2/ Giấy chứng nhận BH TNDS điện tử có giá trị pháp lý tương đương với giấy chứng nhận BH TNDS bản cứng, đã được cơ quan chức năng công nhận theo quy định tại khoản 3 điều 18 Nghị định 03/2021.</p>
									</b-col>
								</b-row>
								<b-row class="align-items-center">
									<b-col cols="6" class="ml-0">
										<button @click="backOnPay" class="btn b2c-search-button-light">Quay lại</button>
									</b-col>
									<b-col cols="6" class="ml-0">
										<button type="button" @click="onPay" class="btn b2c-search-button-red">Thanh toán</button>
									</b-col>
								</b-row>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-2">
							<div class="content-body mx-100" :class="!isActive?'content-body-mb__Active':''">
								<div class="content-body__title">
									<p>PHÍ BẢO HIỂM</p>
								</div>
								<div class="d-flex p-2">
									<p class="content-body__text" style="width: 90%;">Phí BH TNDS</p>
									<p class="content-body__text">{{ MotorStep.PolLcVehPremium }}đ</p>
								</div>
								<div class="d-flex p-2">
									<p class="content-body__text" style="width: 96%;">Phí BH Người Ngồi trên xe</p>
									<p class="content-body__text">{{ MotorStep.polPAandPassager }}đ</p>
								</div>
								<div class="d-flex p-2">
									<p class="content-body__text" style="width: 90%;">VAT</p>
									<p class="content-body__text">{{ MotorStep.PolLcVehVAT }}đ</p>
								</div>
								<div class="content-top"></div>
								<div class="d-flex p-2">
									<p class="content-body__text" style="font-weight: 600; width: 90%;">Tổng phí thực thu</p>
									<p class="content-body__text">{{ MotorStep.polAmount }}đ</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="currentStep==3 && !isPayment">
					<div class="container w-50 mb-3">
						<div class="b2c-pc-header mb-3">
							<h2 class="pl-0">QUÝ KHÁCH VUI LÒNG KIỂM TRA THÔNG TIN</h2>
						</div>
						<div class="card flex-row mb-3">
							<b-col cols="9" class="mx-100">
								<b-row class="container-body">
									<p class="mb-1 mr-1" style="width: 30%;">Tên đơn hàng: </p>
									<p style="font-weight: 400;" class="pxl-0">Bảo hiểm Bắt buộc TNDS cho {{pickedMotor.value }}</p>
									<div class="b2c-pc-header mb-2" style="height: 0;">
										<h2 class="pl-0"></h2>
									</div>
								</b-row>
								<b-row class="container-body">
									<p class="mb-1 mr-1" style="width: 30%;">Thời hạn: </p>
									<p style="font-weight: 400;" class="pxl-0">Từ 00 giờ 00 phút, {{ txtstartdate }} <br/> Đến 23 giờ 59 phút, {{ txtenddate }}</p>
									<div class="b2c-pc-header mb-2" style="height: 0;">
										<h2 class="pl-0"></h2>
									</div>
								</b-row>
								<b-row class="container-body">
									<p class="mb-1 mr-1" style="width: 30%;">Loại xe: </p>
									<p style="font-weight: 400;" class="pxl-0">{{ pickedMotor.value  }}</p>
									<div class="b2c-pc-header mb-2" style="height: 0;">
										<h2 class="pl-0"></h2>
									</div>
								</b-row>
								<b-row class="container-body">
									<p class="mb-1 mr-1" style="width: 30%;">Biển số xe: </p>
									<p style="font-weight: 400;" class="pxl-0">{{ RegnNo }}</p>
									<div class="b2c-pc-header mb-2" style="height: 0;">
										<h2 class="pl-0"></h2>
									</div>
								</b-row>
                <b-row class="container-body">
									<p class="mb-1 mr-1" style="width: 30%;">Số khung: </p>
									<p style="font-weight: 400;" class="pxl-0">{{ ChassisNo}}</p>
									<div class="b2c-pc-header mb-2" style="height: 0;">
										<h2 class="pl-0"></h2>
									</div>
								</b-row>
                <b-row class="container-body">
									<p class="mb-1 mr-1" style="width: 30%;">Email nhận chứng nhận bảo hiểm: </p>
									<p style="font-weight: 400;" class="pxl-0">{{ Email}}</p>
								</b-row>
							</b-col>
							<b-col cols="3" class="ml-2 mx-100 mxl-0">
								<div style="margin-top: 30%;" class="mxt-0">
									<p class="mb-1">TỔNG PHÍ (GỒM VAT)</p>
									<p style="font-size: 30px; font-weight: 700;"><span style="color: red">{{ MotorStep3.polAmount }}đ</span></p>
								</div>
							</b-col>
						</div>
						<div class="card mb-5">
							<h1 style="font-weight: 600; font-size: 20px;" class="mb-3">QUY ĐỊNH VỀ ĐIỀU KHOẢN SỬ DỤNG</h1>
							<div style="border: 1px solid #CBCBCB; border-radius: 10px; height: 120px; overflow-y: scroll;" class="mb-2">
								<p style="" class="p-2">PJICO xác nhận quý khách đồng ý tham gia bảo hiểm sau khi quý khách tích vào ô TÔI ĐÃ ĐỌC, HIỂU VÀ ĐỒNG Ý VỚI QUY TẮC BẢO HIỂM ĐI KÈM SẢN PHẨM NÀY CỦA PJICO và nhấp vào nút thanh toán. Quý khách xác nhận rằng các thông tin nêu trên là dầy đủ và xác thực theo sự hiểu biết cao nhất của quý khách. Quý khách đồng ý rằng, trong trường hợp có bất kỳ sai sót, không đúng sự thật nào trong Đơn đăng ký bảo hiểm này có thể làm mất hiệu lực của Hợp đồng bảo hiểm và số tiền yêu cầu bồi thường có thể không được PJICO chi trả. Sau khi thanh toán thành công, quý khách sẽ nhận được tin nhận thông báo từ PJICO. Đơn bảo hiểm chính thức có hiệu lực sau khi quý khách nhận được giấy chứng nhận bảo hiểm qua hộp thư trực tuyến đã đăng ký. Chi tiết liên hệ: 1900 54 54 55 (1.000đ/phút)</p>
							</div>
							<b-row class="align-items-center mb-2">
								<b-col cols="12" class="d-flex">
									<b-form-checkbox v-model="isCheck" />
									<p class="ml-1">Tôi đã đọc, hiểu và đồng ý với <a target="_blank" href="https://www.pjico.com.vn/wp-content/uploads/2016/06/nghi-dinh-03-2021-bao-hiem-bat-buoc-trach-nhiem-dan-su-cua-chu-xe-co-gioi.pdf"><span style="color: red; text-decoration: underline; cursor: pointer;"><b>quy tắc bảo hiểm</b></span></a> đi kèm với sản phẩm này của PJICO</p>
							</b-col>
							</b-row>
							<b-row class="align-items-center" v-if="!isCheck">
								<p class="ml-1"><span style="color: #EA0029">Bạn chưa đồng ý với điều khoản của chúng tôi</span></p>
							</b-row>
						</div>
						<b-row>
							<b-col cols="6" class="ml-0">
								<button @click="backOnCharge" class="btn b2c-search-button-light">Quay lại</button>
							</b-col>
							<b-col cols="6" class="ml-0">
								<button @click="onPayment" type="button" class="btn btn__title b2c-search-button-red">Thanh toán</button>
							</b-col>
						</b-row>
					</div>
				</div>
				<div v-if="isPayment">
					<div class="container w-50 mb-3">
						<div class="b2c-pc-header mb-3">
							<h2 class="pl-0">CHỌN HÌNH THỨC THANH TOÁN</h2>
						</div>
						<div class="card mx-100" style="width: 550px; padding: 0; margin: 0 auto;">
							<b-row>
								<b-col class="text-center mt-3">
									<p style="font-weight: 600; font-size: 17px; color: #4B4B4B;" class="mb-1">Hình thức thanh toán</p>
									<p style="font-weight: 300; font-size: 12px; color: #4B4B4B" class="mb-2">Lựa chọn hình thức thanh toán</p>
								</b-col>
								<b-col cols="12" class="p-5">
									<div class="bg-white rounded">
										<div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox" v-if="paymentMethodStatus.VIETQR"
												@click="ChangePaymentMethod('VIETQR')">
											<div class="d-flex align-items-center ">
													<b-img width="52px" height="52px" :src="require('@/assets/images/b2c/VNPay.svg')"
															alt="logoVietQR"/>
													<span class="inputText mr-1">Chuyển khoản ngân hàng/VietQR</span>
													<b-img class="mr-1" width="60px" height="20px" :src="require('@/assets/images/b2c/logoVietQR.png')"
															alt="logoVietQR"/>
											</div>
											<b-form-checkbox value="VIETQR" v-model="PaymentChanel" class="vs-checkbox-con" disabled>
											</b-form-checkbox>
										</div>
										<div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox" v-if="paymentMethodStatus.VNPAY_VNPAYQR"
													@click="ChangePaymentMethod('VNPAY')">
											<div class="d-flex align-items-center ">
												<b-img width="52px" height="52px" :src="require('@/assets/images/b2c/VNPay.svg')"
																alt="logoVNPay"/>
												<span class="inputText mr-2">Thanh toán qua </span>
												<b-img width="71px" height="71px" :src="require('@/assets/images/b2c/logoVNPay.svg')"
																alt="logoVNPay"/>
											</div>
											<b-form-checkbox value="VNPAY" v-model="PaymentChanel" class="vs-checkbox-con" disabled>
											</b-form-checkbox>
										</div>
										<div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox" v-if="paymentMethodStatus.VNPAY_VNBANK"
													@click="ChangePaymentMethod('BANK')">
											<div class="d-flex align-items-center ">
												<b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
																alt="logoVNPay"/>
												<span class="inputText">Qua thẻ ATM (có Internet Banking)</span>
											</div>
											<b-form-checkbox value="BANK" v-model="PaymentChanel" disabled>
											</b-form-checkbox>
										</div>
										<div class="d-flex justify-content-between align-items-center paymentBox borderPaymentBox" v-if="paymentMethodStatus.VNPAY_INTCARD"
													@click="ChangePaymentMethod('INTCARD')">
											<div class="d-flex align-items-center ">
												<b-img width="52px" height="52px" :src="require('@/assets/images/b2c/momo.svg')"
																alt="logoVNPay"/>
												<span class="inputText mr-2">Qua thẻ thanh toán quốc tế</span>
												<b-img width="100px" height="43px" :src="require('@/assets/images/b2c/ListCard.png')"
																alt="IntCard"/>
											</div>
											<b-form-checkbox value="INTCARD" v-model="PaymentChanel" disabled>
											</b-form-checkbox>
										</div>
										<div style="margin-bottom: 2%" class="borderPaymentBox">
											<div class="d-flex justify-content-between align-items-center">
												<div class="d-flex align-items-center ">
													<b-img width="52px" height="52px" :src="require('@/assets/images/b2c/ViDienTu.svg')"
																	alt="logoVNPay"/>
													<span class="inputText"> Thanh toán với ví điện tử</span>
												</div>
												<b-form-checkbox v-model="isZaloMono" disabled>
												</b-form-checkbox>
											</div>
											<div>
												<button style="margin-left: 3%" class="buttonZaloMomo" v-if="paymentMethodStatus.ZALOPAY"
																@click="ChangePaymentMethod('ZALOPAY')"
																:class="PaymentChanel==='ZALOPAY'?'buttonZaloMomoActive':''">
													<b-img width="150px" height="42px" :src="require('@/assets/images/b2c/LogoZalo.png')"
																	alt="logoZalo" class="zaloLogo"/>
												</button>
												<button class="buttonZaloMomo" v-if="paymentMethodStatus.MOMO" @click="ChangePaymentMethod('MOMO')"
																:class="PaymentChanel==='MOMO'?'buttonZaloMomoActive':''">
													<b-img height="57px" width="57px" :src="require('@/assets/images/b2c/LoloMomo.png')"
																	alt="logoMomo" class="momoLogo"/>
												</button>
											</div>
										</div>
									</div>
								</b-col>
								<b-row class="px-5">
								<b-col cols="6" class="">
									<button v-if="isRePayment" @click="backButtonPayment" style="color: #ED1F24 !important; border-radius: 10px; border: 1.12253px solid #ED1F24;" class="btn btn-w b2c-search-button-light">Quay lại</button>
								</b-col>
								<b-col cols="6" class="">
									<button @click="nextSuccess" :disabled="!PaymentChanel" style="margin-left: 34%;" type="button" class="btn btn-w b2c-search-button-red myl-0">Tiếp tục</button>
								</b-col>
							</b-row>
							</b-row>
						</div>
					</div>
				</div>
			</div>
			<div v-if="PaymentResponse.Status===1||PaymentResponse.Status===2||PaymentResponse.Status===3">
				<div class="p-1">
					<b-card class="complete">
						<div class="col-md-12">
							<div class="pb-3 pt-3 w-100 text-center">
								<h2 class="huge-text">
									<svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M117.143 54.7756V60.0327C117.136 72.3551 113.146 84.3451 105.768 94.2146C98.3895 104.084 88.0188 111.304 76.202 114.798C64.3853 118.292 51.7558 117.872 40.197 113.602C28.6382 109.331 18.7695 101.439 12.0626 91.1017C5.35581 80.7643 2.17024 68.5359 2.98101 56.2402C3.79179 43.9445 8.55547 32.2403 16.5616 22.8731C24.5677 13.506 35.3873 6.97775 47.4068 4.26212C59.4262 1.54648 72.0015 2.78892 83.2572 7.80414M117.143 14.3185L60 71.5185L42.8571 54.3756" stroke="#40E108" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
									<br><br>Chúc mừng bạn thanh toán thành công!
								</h2>
								<p style="font-weight: 400; color: #666666;" class="mb-3">Chúc mừng bạn đã thanh toán thành công số tiền <b>{{PaymentResponse.TotalPrice}}VND</b><br/>
									Vui lòng kiểm tra email “{{PaymentResponse.Email}}” cho Giấy Chứng Nhận Bảo Hiểm Trách Nhiệm Dân Sự
								</p>
								<div class="p-5 success-body mx-100 mb-3">
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Dịch vụ:</p>
										<p style="padding-left: 42%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.OrderTypeName}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Nhà cung cấp:</p>
										<p style="padding-left: 30%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.SupplierName}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Tên chủ hợp đồng:</p>
										<p style="padding-left: 22%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.FullName}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Mã giao dịch:</p>
										<p style="padding-left: 32%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.Id}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Nội dung:</p>
										<p style="padding-left: 40%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.PartnerOrderId}}</b></p>
									</div>
								</div>
								<p style="font-size: 12px; font-weight: 400; line-height: 20px;" class="mb-2">Mọi thắc mắc về quá trình thanh toán giao dịch <br/>Bạn vui lòng lòng liên hệ iTel Hotline: <b>0877087087</b> (miễn cước iTel)</p>
								<p style="font-size: 12px; font-weight: 400; line-height: 20px;" class="mb-3">Mọi thắc mắc về chính sách, sản phẩm và bản cứng của giấy chứng nhận Bạn vui lòng liên hệ PJICO Hotline: <b>1900 54 54 55</b> (miễn cước iTel)</p>
								<div class="b2c-pc-header" style="height: 0;">
									<h2 class="pl-0"></h2>
								</div>
								<div>
									<SurveyCES :phone="PaymentResponse.ContactPhone" :orderId="PaymentResponse.Id"/>
								</div>
							</div>
						</div>
					</b-card>
				</div>
			</div>
			<div v-else-if="PaymentResponse.Status===0">
				<div class="p-1">
					<b-card class="complete">
						<div class="col-md-12">
							<div class="pb-3 pt-3 w-100 text-center">
								<h2 class="huge-text">
									<svg width="127" height="127" viewBox="0 0 127 127" fill="none"
												xmlns="http://www.w3.org/2000/svg">
											<path
															d="M81.8216 44.75L45.1787 81.3929M45.1787 44.75L81.8216 81.3929M124.572 63.0714C124.572 96.8002 97.229 124.143 63.5001 124.143C29.7713 124.143 2.42871 96.8002 2.42871 63.0714C2.42871 29.3426 29.7713 2 63.5001 2C97.229 2 124.572 29.3426 124.572 63.0714Z"
															stroke="#ED1F24" stroke-width="4" stroke-linecap="round"
															stroke-linejoin="round"/>
									</svg>
									<br><br>Thanh toán thất bại
								</h2>
								<div class="p-5 success-body mx-100 mb-3">
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Dịch vụ:</p>
										<p style="padding-left: 42%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.OrderTypeName}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Nhà cung cấp:</p>
										<p style="padding-left: 30%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.SupplierName}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Tên chủ hợp đồng:</p>
										<p style="padding-left: 22%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.FullName}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Mã giao dịch:</p>
										<p style="padding-left: 32%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.Id}}</b></p>
									</div>
									<div class="d-flex p-1">
										<p class="content-body__text" style="color: #333333;">Nội dung:</p>
										<p style="padding-left: 40%; color: #333333;" class="content-body__text"><b>{{PaymentResponse.PartnerOrderId}}</b></p>
									</div>
								</div>
								<p style="font-size: 12px; font-weight: 400; line-height: 20px;" class="mb-2">Mọi thắc mắc về quá trình thanh toán giao dịch <br/>Bạn vui lòng lòng liên hệ iTel Hotline: <b>0877087087</b> (miễn cước iTel)</p>
								<p style="font-size: 12px; font-weight: 400; line-height: 20px;" class="mb-3">Mọi thắc mắc về chính sách, sản phẩm và bản cứng của giấy chứng nhận Bạn vui lòng liên hệ PJICO Hotline: <b>1900 54 54 55</b> (miễn cước iTel)</p>
							</div>
							<div class="w-100 pt-1 pb-2 text-center">
								<b-button
								variant="primary" class="style-button mb-2" @click="thanhToanLai">Thanh toán lại</b-button>
								<b-button variant="outline-danger" class="style-button" @click="$router.push({name: 'home'});">Quay về trang chủ</b-button>
							</div>
						</div>
					</b-card>
				</div>
			</div>
		<b-modal id="bv-modal-onPayment-VietQR" title="VietQR" hide-footer>
					<div class="d-block border-danger rounded mb-3 onPayment-w">
							<div class="b2c-payment">
									<div class="d-block p-2 onPayment-w">
											<p class="mb-2 b2c-badge"><b>Cách 1: </b>Chuyển khoản bằng mã QR Mở App Ngân Hàng Quét QRCode</p>
											<p class="text-center mb-2">
											<img :src="vietQr.qrUrl" alt="VietQR-payment" style="width: 230px; height: auto;">
											</p>
											<div class="text-center img__btn text-decoration-underline mb-2" style="color: #72A0B3;" @click="downloadFile()">
													Tải xuống
											</div>
											<div class="b2c-note d-flex mb-2">
													<div style="margin-top: -0.5rem;">
															<feather-icon size="24" icon="AlertTriangleIcon"/>
													</div>
													<p class="b2c-badge ml-1"><b>Lưu ý: Bạn vui lòng không thay đổi nội dung thanh toán</b></p>
											</div>
											<div class="line"></div>
									</div>
									<div class="d-block p-2 onPayment-w">
											<p class="mb-2 b2c-badge"><b>Cách 2: </b>Chuyển khoản THỦ CÔNG theo thông tin</p>
											<div class="d-flex mb-2">
													<p class="b2c-badge" style="width: 50%;">Ngân hàng:</p>
													<p class="b2c-badge"><b>{{ vietQr.bankName }}</b></p>
											</div>
											<div class="d-flex mb-2">
													<p class="b2c-badge" style="width: 50%;">Chủ tài khoản:</p>
													<p class="b2c-badge"><b>{{ vietQr.bankAccountName }}</b></p>
											</div>
											<div class="d-flex mb-2">
													<p class="b2c-badge" style="width: 80%;">Số tài khoản:</p>
													<p class="b2c-badge" style="width: 60%;"><b>{{ vietQr.bankAccountNo }}</b></p>
													<div @click="myCopyText(vietQr.bankAccountNo)">
															<img class="img__btn" src="@/assets/images/b2c/btn_coppy_vietqr.svg" alt="VietQR-payment"/>
													</div>
											</div>
											<div class="d-flex mb-2">
													<p class="b2c-badge" style="width: 80%;">Số tiền:</p>
													<p class="b2c-badge" style="width: 60%;"><b>{{ vietQr.amount }}đ</b></p>
													<div @click="myCopyText(vietQr.amount)">
															<img class="img__btn" src="@/assets/images/b2c/btn_coppy_vietqr.svg" alt="VietQR-payment"/>
													</div>
											</div>
											<div class="d-flex mb-2">
													<p class="b2c-badge" style="width: 80%;">Nội dung:</p>
													<p class="b2c-badge" style="width: 60%;"><b style="color: #EA0029;">{{ vietQr.info }}</b></p>
													<div @click="myCopyText(vietQr.info)">
															<img class="img__btn" src="@/assets/images/b2c/btn_coppy_vietqr.svg" alt="VietQR-payment"/>
													</div>
											</div>
											<div class="b2c-note d-flex">
													<div style="margin-top: 0.5rem;">
															<feather-icon size="24" icon="AlertTriangleIcon"/>
													</div>
													<p class="b2c-badge ml-1"><b>Lưu ý: Bạn vui lòng nhập đúng nội dung chuyển khoản <b style="color: #EA0029;">{{ vietQr.info }}</b>. Bạn sẽ nhận được email (hoặc SMS) xác nhận khi giao dịch thành công.</b></p>
											</div>
									</div>
							</div>
					</div>
					<div class="d-flex justify-content-end payment-center b2c-payment">
							<p v-if="isTextVietQR" class="b2c-badge mb-3">Đơn hàng của bạn sẽ được xác nhận thanh toán tự động trong 1-5 phút tới,
							chúng tôi sẽ gửi Tin nhắn Zalo/SMS xác nhận đến số điện thoại liên lạc khi đã hoàn tất</p>
							<b-button class="mb-3" v-if="isOnVietQR" style="background: #ed1f24 !important; border: none !important; font-size: 14px !important; border-radius: 3rem !important; width: 276px; height: 48px;" @click="onPaymentVietQR">
							Tôi đã thanh toán
							</b-button>
					</div>
		</b-modal>
		<b-modal
			size="lg"
			body-class="p-2"
			hide-header
			hide-footer
			id="modal-Privacy-Policy"
			title=""
			>
			<div class="p-1">
				<p style="font-size: 18px; font-weight: 700; font-style: normal; text-align: center;" class="mb-2">CHÍNH SÁCH VỀ QUYỀN RIÊNG TƯ </p>
				<div class="pre-scrollable">
					<div class="p-1">
						<p class="mb-2" style="font-size: 16px; font-weight: 400;"><b style="font-size: 18px;">Công ty Cổ phần Viễn thông Di động Đông Dương Telecom</b> cam kết bảo vệ sự riêng tư của bạn cũng như tuân thủ các yêu cầu về bảo vệ thông tin cá nhân theo luật pháp Việt Nam. </p>
						<ul class="ml-5 mb-1">
							<li class="mb-1">Khi bạn sử dụng trang tin này, ITEL sẽ không thu thập bất kỳ thông tin cá nhân nào của bạn cho đến khi:</li>
							<ul class="ml-5" style="list-style: circle;">
								<li class="mb-1">Bạn xác định thật sự muốn mua/ sử dụng dịch vụ sản phẩm của ITEL, đăng ký thành viên truy cập các trang web/ app của ITEL điền thông tin cho mục đích xin việc hay để liên hệ với ITEL. </li>
								<li class="mb-1">Khi bạn trực tiếp tương tác với chúng tôi, bao gồm khi bạn tương tác với các nhân viên dịch vụ khách hàng được thuê ngoài của chúng tôi. </li>
								<li class="mb-1">Khi bạn đăng ký sử dụng, hoặc sử dụng theo bất kỳ hình thức nào khác bất kỳ Dịch Vụ nào trên Nền Tảng của chúng tôi và/hoặc trên các nền tảng của những bên liên kết của chúng tôi. </li>
							</ul>
							<li class="mb-1">Ngoại trừ những trường hợp được liệt kê ở mục “Thu thập thông tin cá nhân” bên dưới đây, ITEL sẽ không tiết lộ bất kỳ thông tin cá nhân nào của bạn cho bên thứ ba trừ khi có sự cho phép của bạn hay trong các trường hợp đặc biệt.</li>
							<li class="mb-1">ITEL sẽ thực hiện các bước cần thiết để đảm bảo rằng thông tin cá nhân bạn cung cấp cho công ty được lưu trữ an toàn.</li>
							<li>Nếu bạn có bất kỳ thắc mắc liên quan đến chính sách cũng như các thông lệ về quyền riêng tư của công ty, vui lòng liên hệ trực tiếp với công ty chúng tôi.</li>
						</ul>
						<p style="font-weight: 700; font-style: normal; font-size: 16px;">Thu thập thông tin cá nhân</p>
						<p style="font-weight: 700; font-style: normal; font-size: 16px;">Dưới đây là các mục đích sử dụng thông tin cá nhân được thu thập bởi ITEL từ người sử dụng trang tin: </p>
						<div class="ml-2">
							<p style="font-weight: 400;">(1) Cung cấp, thiết kế và tiếp thị (bao gồm tiếp thị trực tiếp) các sản phẩm viễn thông, bảo hiểm, các dịch vụ tài chính hoặc các sản phẩm liên quan của ITEL, các thành viên hoặc các đối tác tài chính, đời sống, du lịch, viễn thông, dịch vụ thương mại;</p>
							<p style="font-weight: 400;">(2) Hợp tác cùng đối tác bảo hiểm kiểm tra bất kỳ về y tế, an ninh và thẩm định cũng như các yêu cầu về bồi thường bảo hiểm;</p>
							<p style="font-weight: 400;">(3) Thống kê và nghiên cứu; thực hiện khảo sát, bao gồm thực hiện nghiên cứu về nhân khẩu học và hành vi của người dùng của chúng tôi để cải tiến công nghệ hiện tại của chúng tôi (ví dụ: công nghệ nhận dạng giọng nói, v.v.) thông qua học máy hoặc các phương thức khác;</p>
							<p style="font-weight: 400;">(4) Để phản hồi các thắc mắc, phản hồi, khiếu nại hoặc tranh chấp của bạn, dù trực tiếp hay thông qua các bên cung cấp dịch vụ thứ ba của chúng tôi;</p>
							<p style="font-weight: 400;">(5) Đáp ứng yêu cầu công bố theo quy định pháp luật hoặc cơ quan có thẩm quyền đối với ITEL, các thành viên hoặc các đối tác tài chính, đời sống, du lịch, viễn thông, dịch vụ thương mại;</p>
							<p style="font-weight: 400;">(6) Để hỗ trợ việc bạn sử dụng các Dịch Vụ hoặc truy cập vào Nền Tảng</p>
							<p style="font-weight: 400;">(7) Để quản trị và quản lý tài khoản trực tuyến mà bạn khởi tạo với chúng tôi</p>
							<p style="font-weight: 400;">(8) Để phát triển thêm các sản phẩm và dịch vụ của chúng tôi</p>
							<p style="font-weight: 400;">(9) Để giúp chúng tôi thực hiện việc tiếp thị và quảng cáo</p>
							<p style="font-weight: 400;">(10) Thực hiện các mục tiêu khác liên quan theo điểm (1) đến (9) ở trên</p>
						</div>
						<p style="font-weight: 700; font-style: normal; font-size: 16px;" class="mb-2">Thông tin cá nhân của người sử dụng được thu thập bởi ITEL thông qua trang tin này có thể được chuyển giao và tiết lộ cho bất kỳ bên nào sau đây (bất kể trong hay ngoài Việt Nam) cho các mục đích như đã nếu ở các mục từ (1) đến (6) ở trên:</p>
						<div class="ml-2">
							<p style="font-weight: 400;">(a) Các công ty liên kết của ITEL;</p>
							<p style="font-weight: 400;">(b) Các công ty kinh doanh bảo hiểm và/hoặc tái bảo hiểm;</p>
							<p style="font-weight: 400;">(c) Các trung gian bảo hiểm có hợp đồng đại lý hay môi giới với công ty ITEL;</p>
							<p style="font-weight: 400;">(d) Nhân viên hay tổ chức cung cấp dịch vụ điều tra bồi thường bảo hiểm;</p>
							<p style="font-weight: 400;">(e) Các hiệp hội và liên đoàn công nghiệp bảo hiểm của ngành bảo hiểm tuỳ từng thời điểm;</p>
							<p style="font-weight: 400;">(f) Các đơn vị cung cấp dịch vụ liên quan đến việc kinh doanh bảo hiểm và/hoặc tái bảo hiểm;</p>
							<p style="font-weight: 400;">(g) Các đại lý, thành viên, nhà thầu hay nhà cung cấp dịch vụ thứ ba cung cấp dịch vụ hành chính, viễn thông, máy tính, thanh toán hay các dịch vụ khác cho ITEL liên quan đến công việc kinh doanh của công ty ITEL.</p>
							<p style="font-weight: 400;">(h) Các đại lý giới thiệu tín dụng;</p>
							<p style="font-weight: 400;">(i) Các đại lý thu hồi công nợ/ thu hộ;</p>
							<p style="font-weight: 400;">(j) Các cá nhân hoặc pháp nhân mà ITEL có nghĩa vụ cung cấp thông tin theo yêu cầu của luật định liên quan đến ITEL hoặc bất kỳ thành viên hoặc đối tác kinh doanh nào của công ty;</p>
							<p style="font-weight: 400;">(k) Các cơ quan hành pháp, tư pháp hoặc lập pháp.</p>
						</div>
						<p style="font-weight: 700; font-style: normal; font-size: 16px;">Người sử dụng trang tin này có quyền yêu cầu truy xuất và điều chỉnh thông tin cá nhân của mình được lưu trữ bởi ITEL. Nếu người sử dụng của trang tin này muốn truy xuất hoặc chỉnh sửa thông tin cá nhân của mình được lưu trữ bởi công ty ITEL, vui lòng liên hệ chúng tôi: “Công ty Cổ phần Viễn thông Di động Đông Dương Telecom ” tại địa chỉ B018, Tháp The Manor, Mễ Trì, Q. Nam Từ Liêm, Hà Nội.</p>
					</div>
				</div>
			</div>
			<div class="col-12 text-center pt-0 mt-0 mb-2">
				<b-button @click="$bvModal.hide('modal-Privacy-Policy')" style="width: 140px !important; height: 40px !important; font-size: 14px !important; color: #fff !important; border-radius: 16px !important; background: #ED1F24 !important; border: none !important;">Đóng</b-button>
        </div>
		</b-modal>
	</div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import SurveyCES from "@/components/SurveyCES.vue"
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required, min, email} from "@validations";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import vSelect from "vue-select";
import {
  BCard,
  BCardText,
  BCardHeader,
  BFormInput,
  BCardBody,
  BCardFooter,
	BFormRadio,
	BFormRadioGroup,
  BRow,
  BCol,
  BModal,
	BFormCheckbox,
	BButton,
	BCalendar,
	BImg,
} from "bootstrap-vue";
import PjiCoStep from "@/components/PjiCoStep.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
	components: {
		BCard,
		BButton,
		BCardText,
		BFormCheckbox,
		BFormRadio,
		BImg,
		SurveyCES,
		BFormRadioGroup,
		vSelect,
		DatePicker,
		BCardHeader,
		BFormInput,
		BCardBody,
		BCardFooter,
		BCalendar,
		BRow,
		BCol,
		BModal,
		PjiCoStep,
		FeatherIcon,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
      isRePayment: true,
			MotorStep: {},
			MotorStep3: {},
			pickedMotor: '',
			Motor:[],
			isCurrentStep: false,
			currentStep: 1,
			isActive: false,
			isCheck: false,
			txtstartdate: moment(new Date()).format('[ngày ]DD[ tháng ]MM[ năm ]YYYY').toString(),
			txtenddate: moment(new Date()).add(1, 'years').add(-1, 'days').format('[ngày ]DD[ tháng ]MM[ năm ]YYYY'),
			startdate: moment(new Date()).format("DD/MM/YYYY").toString(),
			enddate: moment(new Date()).add(1, 'years').add(-1, 'days').format('DD/MM/YYYY'),
			isPayment: false,
			PaymentChanel: "",
			isZaloMono: false,
			isSuccess:  false,
			PaymentResponse: '',
			type: localStorage.getItem('VehicleType'),
			Vehicle: 1,
			VehUseType: 1,
			SeatingCapacity: 2,
			PolLcVehPremium: 10000000,
			IsPAforCoDriverPol: 1,
			PALevelAmount: 10000000,
			NumOfDrivers: 0,
			IsPassagerPol: 1,
			PassagerLevelAmount: 10000000,
			NumOfPassagers: 2,
			FullName: '',
			Address: '',
			Phone: '',
			Email: '',
			custName: '',
			custId: '',
			custAddress: '',
			RegnNo: '',
			ChassisNo: '',
			EngineNo: '',
			duration: '1 năm',
			cart_info: {},
			isOnVietQR: true,
      isTextVietQR: false,
			vietQr: {},
			orderId:"",
			required,
			min,
			email,
			paymentMethodStatus: {
        "VIETQR": 1,
        "VNPAY_VNPAYQR": 1,
        "VNPAY_INTCARD": 1,
        "VNPAY_VNBANK": 1,
        "MOMO": 1,
        "ZALOPAY": 1,
        "COD": 1
      },
		};
	},
	mounted() {
		this.getPaymentMethod()
		this.getMotorcycle();
		if(this.$route.query.orderId) {
			this.orderId = this.$route.query.orderId
			this.onPaymentSuccess()
		}
	},
	methods: {
    HandleChassisNoChange(){
      this.ChassisNo=this.ChassisNo.trim()
    },
		getPaymentMethod() {
      useJwt
          .post("get-payment-method", {
              cart: this.cart
          })
          .then((response) => {
              this.paymentMethodStatus = {
                  "VIETQR": response.data.result.VIETQR,
                  "VNPAY_VNPAYQR": response.data.result.VNPAY_VNPAYQR,
                  "VNPAY_INTCARD": response.data.result.VNPAY_INTCARD,
                  "VNPAY_VNBANK": response.data.result.VNPAY_VNBANK,
                  "MOMO": response.data.result.MOMO,
                  "ZALOPAY": response.data.result.ZALOPAY,
                  "COD": response.data.result.COD
              }
          });
        },
		myCopyText(textValue) {
      navigator.clipboard.writeText(textValue);
      this.$toast.success("Sao chép thành công", {icon: true});
    },
    async downloadFile() {
        const blob = await (await fetch(this.vietQr.qrUrl)).blob();
        const url = URL.createObjectURL(blob);
        Object.assign(
        document.createElement('a'),
        {
            href: url,
            download: this.vietQr.qrUrl + '.jpg'
        }).click();
        URL.revokeObjectURL(url);
    },
		onPaymentSuccess() {
      this.loading(true)
			useJwt.get(`order/get-status/` + this.orderId)
      .then((response) => {
          this.PaymentResponse = response.data.result[0];
					this.loading(false)
					this.$bvModal.hide('bv-modal-onPayment-VietQR')
				}).catch((err) => {
					this.loading(false)
					this.$toast.error("Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!", {
						icon: true,
						closeButton: "button"
					});
				});
		},
		onPaymentVietQR() {
      this.loading(true)
      this.isTextVietQR = true;
      this.isOnVietQR = false;
      this.onPaymentSuccess();
    },
		backOnCharge() {
			this.currentStep = 2;
		},
		async onCharge() {
      const startdate=moment(this.startdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if(new Date(startdate)<new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate(),7) && this.currentStep===2){
        this.$toast.warning(
            "Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại!",
            {icon: true, closeButton: "button"}
        );
        this.startdate=moment(new Date()).format('DD/MM/YYYY')
        return;
      }
			var validate = await this.$refs.infoValid.validate().then((success) => {
				return success;
			});
			if (!validate) {
				this.$toast.warning(
					"Vui lòng nhập đủ thông tin!",
					{icon: true, closeButton: "button"}
					);
					return;
			}
				this.loading(true);
				let body = {
				Phone: this.Phone,
				Email: this.Email,
				custName: this.custName,
				custAddress: this.custAddress,
				EngineNo: this.EngineNo,
				ChassisNo: this.ChassisNo,
				RegnNo: this.RegnNo,
				custId: this.custId,
				FullName: this.FullName,
				Address: this.Address,
				Vehicle: this.Vehicle,
				VehicleType: this.pickedMotor.key,
				SeatingCapacity: parseInt(this.SeatingCapacity),
				PolLcVehPremium: this.PolLcVehPremium,
				IsPAforCoDriverPol: this.IsPAforCoDriverPol,
				PALevelAmount: this.PassagerLevelAmount,
				VehUseType:  parseInt(this.VehUseType),
				NumOfDrivers: parseInt(this.NumOfDrivers),
				IsPassagerPol: this.IsPassagerPol,
				PassagerLevelAmount: this.PassagerLevelAmount,
				NumOfPassagers: parseInt(this.SeatingCapacity) - parseInt(this.NumOfDrivers),
			}
			if (!this.isActive) {
					body.NumOfDrivers = 0
					body.NumOfPassagers = 0
					body.IsPassagerPol = 0
					body.IsPAforCoDriverPol = 0
				}
				await useJwt.post("pjico/calPrice", body ).then(response => {
				this.currentStep = 2;
        this.ScrollToTop()
				this.loading(false);
				this.MotorStep = response.data.result
      }).catch(err => {
				this.loading(false);
				this.$toast.error(
          "Quý khách vui lòng nhập đầy đủ thông tin !",
          { icon: true, closeButton: "button" }
        );
      })
		},
		getMotorcycle() {
			useJwt.get("pjico/meta/MotorType").then(response => {
				this.Motor = response.data.result;
				console.log('res', this.Motor);
      }).catch(err => {
				this.loading(false);
				this.$toast.err(
          "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      })
		},
		backButton() {
      this.ScrollToTop()
			this.isPayment = false
			if(this.currentStep === 1) {
				this.$router.push({ path: '/bh-pjico'});
			}
			else {
				this.currentStep -= 1;
			}
		},
		backOnPay() {
      this.ScrollToTop()
			this.currentStep = 1
		},
		thanhToanLai() {
			this.PaymentResponse = !this.PaymentResponse
      this.isRePayment = false
			this.currentStep = 3
			this.isPayment = true
		},
    ScrollToTop(){
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    },
		async onPay() {
			var validate = await this.$refs.infoValid.validate().then((success) => {
				return success;
			});
			if (!validate) {
				this.$toast.warning(
					"Vui lòng nhập thời hạn bảo hiểm!",
					{icon: true, closeButton: "button"}
					);
					return;
			}
			await useJwt.post("pjico/calPrice", {
				Phone: this.Phone,
				startdate: this.startdate,
				enddate: this.enddate,
				Email: this.Email,
				custName: this.custName,
				custAddress: this.custAddress,
				EngineNo: this.EngineNo,
				ChassisNo: this.ChassisNo,
				RegnNo: this.RegnNo,
				custId: this.custId,
				Vehicle: this.Vehicle,
				VehicleType: this.pickedMotor.key,
				VehUseType:  parseInt(this.VehUseType),
				SeatingCapacity: this.SeatingCapacity,
				PolLcVehPremium: this.PolLcVehPremium,
				IsPAforCoDriverPol: !this.isActive ? 0 : this.IsPAforCoDriverPol,
				PALevelAmount: this.PALevelAmount,
				NumOfDrivers: !this.isActive ? 0 : parseInt(this.NumOfDrivers),
				IsPassagerPol: !this.isActive ? 0 : this.IsPassagerPol,
				PassagerLevelAmount: this.PassagerLevelAmount,
				NumOfPassagers: !this.isActive ? 0 : this.NumOfPassagers,
			}).then(response => {
				this.MotorStep3 = response.data.result
        this.ScrollToTop()
				this.currentStep = 3
				this.loading(false);
      }).catch(err => {
				this.loading(false);
				this.$toast.error(
          "Quý khách vui lòng nhập đầy đủ thông tin !",
          { icon: true, closeButton: "button" }
        );
      })
		},
		onPayment() {
			if(this.isCheck === true) {
				this.isPayment = true;
			} else {
				this.$toast.error(
          "Bạn chưa đồng ý với điều khoản của chúng tôi !",
          { icon: true, closeButton: "button" }
        );
			}
		},
		backButtonPayment() {
			this.isPayment = false;
		},
		nextSuccess() {
			if(this.orderId) {
				useJwt.post("order/pay-order", {
					orderIds: [this.orderId],
					paymentChannel: this.PaymentChanel,
				})
				.then((response) => {
						if(response.data.result.paymentUrl) {
							document.location = response.data.result.paymentUrl;
						} else {
							this.vietQr = response.data.result;
							this.orderId = response.data.result.orderId
							this.loading(false);
							this.$bvModal.show('bv-modal-onPayment-VietQR')
							this.isTextVietQR = false;
							this.isOnVietQR = true;
						}
						this.loading(false)
					}).catch((err) => {
						this.loading(false)
						this.$toast.error("Có lỗi trong khi hoàn thành đơn hàng, Quý khách vui lòng thử lại!", {
							icon: true,
							closeButton: "button"
						});
					});
			} else {
				switch (this.PaymentChanel) {
					case "VNPAY":
							return this.thanhtoan("VNPAY");
					case "BANK":
							return this.thanhtoan("VNBANK");
					case "INTCARD":
							return this.thanhtoan("INTCARD");
					case "VIETQR":
							return this.thanhtoanVIETQR();
					case "MOMO":
							return this.thanhtoanMOMO();
					case "ZALOPAY":
							return this.thanhtoanZALO();
				}
			}
		},
		thanhtoanZALO() {
			useJwt.post("pjico/payment", {
				PaymentChanel: this.PaymentChanel,
				Source: this.getSource,
				Phone: this.Phone,
				startdate: this.startdate,
				enddate: this.enddate,
				duration: this.duration,
				Email: this.Email,
				FullName: this.FullName,
				Address: this.Address,
				custName: this.custName,
				custAddress: this.custAddress,
				EngineNo: this.EngineNo,
				ChassisNo: this.ChassisNo,
				RegnNo: this.RegnNo,
				custId: this.custId,
				Vehicle: this.Vehicle,
				VehicleType: this.pickedMotor.key,
				VehUseType:  parseInt(this.VehUseType),
				SeatingCapacity: this.SeatingCapacity,
				PolLcVehPremium: this.PolLcVehPremium,
				IsPAforCoDriverPol: !this.isActive ? 0 : this.IsPAforCoDriverPol,
				PALevelAmount: !this.isActive ? 0 : this.PALevelAmount,
				NumOfDrivers: parseInt(this.NumOfDrivers),
				IsPassagerPol: !this.isActive ? 0 : this.IsPassagerPol,
				PassagerLevelAmount: !this.isActive ? 0 : this.PassagerLevelAmount,
				NumOfPassagers: this.NumOfPassagers,
			})
			.then((response) => {
				document.location = response.data.result.paymentUrl;
				this.loading(false);
			})
			.catch((err) => {
				this.loading(false);
				this.$toast.error("Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",{icon: true, closeButton: "button"});});
		},
		thanhtoanMOMO(){
			useJwt.post("pjico/payment", {
				PaymentChanel: this.PaymentChanel,
				Source: this.getSource,
				Phone: this.Phone,
				startdate: this.startdate,
				enddate: this.enddate,
				duration: this.duration,
				Email: this.Email,
				FullName: this.FullName,
				Address: this.Address,
				custName: this.custName,
				custAddress: this.custAddress,
				EngineNo: this.EngineNo,
				ChassisNo: this.ChassisNo,
				RegnNo: this.RegnNo,
				custId: this.custId,
				Vehicle: this.Vehicle,
				VehicleType: this.pickedMotor.key,
				VehUseType:  parseInt(this.VehUseType),
				SeatingCapacity: this.SeatingCapacity,
				PolLcVehPremium: this.PolLcVehPremium,
				IsPAforCoDriverPol: !this.isActive ? 0 : this.IsPAforCoDriverPol,
				PALevelAmount: !this.isActive ? 0 : this.PALevelAmount,
				NumOfDrivers: parseInt(this.NumOfDrivers),
				IsPassagerPol: !this.isActive ? 0 : this.IsPassagerPol,
				PassagerLevelAmount: !this.isActive ? 0 : this.PassagerLevelAmount,
				NumOfPassagers: this.NumOfPassagers,
			})
			.then((response) => {
				document.location = response.data.result.paymentUrl;
				this.loading(false);
			})
			.catch((err) => {
				this.loading(false);
				this.$toast.error("Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",{icon: true, closeButton: "button"});});
		},
		thanhtoanVIETQR() {
			useJwt.post("pjico/payment", {
				PaymentChanel: this.PaymentChanel,
				Source: this.getSource,
				Phone: this.Phone,
				startdate: this.startdate,
				enddate: this.enddate,
				duration: this.duration,
				Email: this.Email,
				FullName: this.FullName,
				Address: this.Address,
				custName: this.custName,
				custAddress: this.custAddress,
				EngineNo: this.EngineNo,
				ChassisNo: this.ChassisNo,
				RegnNo: this.RegnNo,
				custId: this.custId,
				Vehicle: this.Vehicle,
				VehicleType: this.pickedMotor.key,
				VehUseType:  parseInt(this.VehUseType),
				SeatingCapacity: this.SeatingCapacity,
				PolLcVehPremium: this.PolLcVehPremium,
				IsPAforCoDriverPol: !this.isActive ? 0 : this.IsPAforCoDriverPol,
				PALevelAmount: !this.isActive ? 0 : this.PALevelAmount,
				NumOfDrivers: parseInt(this.NumOfDrivers),
				IsPassagerPol: !this.isActive ? 0 : this.IsPassagerPol,
				PassagerLevelAmount: !this.isActive ? 0 : this.PassagerLevelAmount,
				NumOfPassagers: this.NumOfPassagers,
			})
			.then((response) => {
				this.vietQr = response.data.result;
				this.orderId = response.data.result.orderId
				this.loading(false);
				this.$bvModal.show('bv-modal-onPayment-VietQR')
				this.isTextVietQR = false;
				this.isOnVietQR = true;
			})
			.catch((err) => {
				this.loading(false);
				this.$toast.error("Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",{icon: true, closeButton: "button"});});
		},
		thanhtoan(vnp_BankCode) {
      useJwt.post("pjico/payment", {
				PaymentChanel: this.PaymentChanel,
				vnp_BankCode: vnp_BankCode,
				Source: this.getSource,
				Phone: this.Phone,
				startdate: this.startdate,
				enddate: this.enddate,
				duration: this.duration,
				Email: this.Email,
				FullName: this.FullName,
				Address: this.Address,
				custName: this.custName,
				custAddress: this.custAddress,
				EngineNo: this.EngineNo,
				ChassisNo: this.ChassisNo,
				RegnNo: this.RegnNo,
				custId: this.custId,
				Vehicle: this.Vehicle,
				VehicleType: this.pickedMotor.key,
				VehUseType:  parseInt(this.VehUseType),
				SeatingCapacity: this.SeatingCapacity,
				PolLcVehPremium: this.PolLcVehPremium,
				IsPAforCoDriverPol: !this.isActive ? 0 : this.IsPAforCoDriverPol,
				PALevelAmount: !this.isActive ? 0 : this.PALevelAmount,
				NumOfDrivers: parseInt(this.NumOfDrivers),
				IsPassagerPol: !this.isActive ? 0 : this.IsPassagerPol,
				PassagerLevelAmount: !this.isActive ? 0 : this.PassagerLevelAmount,
				NumOfPassagers: this.NumOfPassagers,
			})
			.then((response) => {
				document.location = response.data.result.paymentUrl;
				this.loading(false);
			})
			.catch((err) => {
				this.loading(false);
				this.$toast.error("Có lỗi trong khi thực hiện thanh toán, Quý khách vui lòng thử lại!",{icon: true, closeButton: "button"});});
    },
		ChangePaymentMethod(method) {
      this.PaymentChanel = method;
    },
		formatPhone(e) {
      return String(e).substring(0, 10);
    },
		formatCustID(e) {
      return String(e).substring(0, 12);
    },
	},
	watch: {
    PaymentChanel: function (i, v) {
      if (i === "ZALOPAY" || i === "MOMO") {
        this.isZaloMono = true;
      } else {
        this.isZaloMono = false
      }
    },
		startdate:function(i,v) {
			this.enddate = moment(moment(this.startdate, 'DD/MM/YYYY').add(1, 'years').add(-1, 'days')).format('DD/MM/YYYY');
			this.txtstartdate =moment(this.startdate, 'DD/MM/YYYY').format('[ngày ]DD[ tháng ]MM[ năm ]YYYY')
			this.txtenddate = moment(moment(this.startdate, 'DD/MM/YYYY').add(1, 'years').add(-1, 'days'), 'DD/MM/YYYY').format('[ngày ]DD[ tháng ]MM[ năm ]YYYY')
		},
	},
	computed: {
    getSource() {
      return this.$store.getters[`app/getSource`]
    }
  },
}
</script>

<style lang="scss" scoped>
.feather-alert-triangle {
	width: 15px;
}
.b2c-note {
	border-radius: 5px;
	background: antiquewhite;
	padding: 10px !important;
}
#bv-modal-onPayment-VietQR___BV_modal_outer_ {
	z-index: 100 !important;
}
.modal-title {
  margin: 20px 0 0 20px;
  font-size: 20px !important;
  color: #424242;
  font-weight: 700;
}
.img__btn:hover {
  opacity: 0.8;
  cursor: pointer;
}
.line {
  position: absolute;
  border: 1px dashed #EA0029;
  transform: rotate(180deg);
  width: 96%;
  left: 16px;
}
.success-body {
	margin: 0 auto;
	width: 380px;
	border-radius: 8px;
	box-shadow: rgba(170, 170, 170, 0.2) 0px 5px 20px;
}
.b2c-badge {
  font-size: 14px;
}
.complete {
	margin: 0 auto;
	max-width: 562px;
}

.complete .card-body {
	padding: 0 !important;
}

.huge-text {
	font-size: 24px !important;
	font-weight: 700 !important;
	margin-bottom: 16px;
}
.paymentBox {
  height: 52px;
  margin-bottom: 4%;
}
.inputText {
  font-size: 18px;
}
.borderPaymentBox {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.buttonZaloMomo {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 8px;
  margin: 2%;
  width: 220px;
  height: 80px
}
.buttonZaloMomoActive {
  border: 1px solid #FF0000;
}
.content-top {
	opacity: 0.6;
	border: 0.7px solid #BFBFBF;
}
.content-body__title {
	background: #EA0029;
	border-radius: 10px 10px 0px 0px;
	height: 44px;
}
.content-body__text {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 141.69%;
	color: #5F5F5F;
}
.content-body__title p {
	padding: 12px;
	color: #fff;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 141.69%;
}
.content-body {
	position: relative;
	right: 120%;
	top: 12%;
	width: 400px;
	background: #FFFFFF;
	border: 1px solid rgba(212, 212, 212, 0.9) !important;
	border-radius: 10px;
}
.b2c-search-button-light {
	margin-bottom: 100px;
	width: 200px;
	font-weight: 700;
	font-size: 16px !important;
	color: #5C5C5C !important;
	background: #FFFFFF !important;
	border-radius: 5px !important;
	height: 48px !important;
}
.btn__title {
	margin-left: -30%;
}

.b2c-search-button-red {
	margin-bottom: 100px;
	width: 200px;
	font-weight: 700;
	font-size: 16px !important;
	color: #ffffff !important;
	background: #ed1f24 !important;
	border-radius: 5px !important;
	height: 48px !important;
}
.b2c-search-button-light:hover,
.b2c-search-button-red:hover {
	opacity: 0.8;
}
.w-60 {
	width: 60%;
}
p {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
}
ul {
	color: #000000;
	font-size: 14px;
	font-style: normal;
	list-style: disc;
	line-height: 18px;
}
.b2c-pc-header-content {
	background: #F3F3F3;
	border-radius: 16px;
}
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-pc-header {
	margin-left: auto;
	margin-right: auto;
	height: 70px;
	width: 100%;
}
.b2c-pc-header h2 {
	border-bottom: 1px solid #dadada;
	font-size: 24px;
	font-weight: 700;
	line-height: 69px;
	padding-left: 15%;
	color: #424242;
	width: 100%;
}
.style-button {
  width: 100%;
  border-radius: 27px !important;
  font-size: 18px;
  font-weight: 600;
}
// .mx-datepicker {
//   width: 210px;
// }
@media screen and (max-width: 450px) {
	.line {
    width: 91%;
}
	.mxb-5 {
		margin-bottom: 40%;
	}
	.b2c-search-button-red-ml {
		margin: 0 25% 50px !important;
	}
	.b2c-payment {
    flex-direction: column;
  }
  .onPayment-w {
    width: 100% !important;
  }
	.payment-center {
    justify-content: center !important;
  }
  .payment-center button {
    width: 100% !important;
  }
	.b2c-search-button-light,
	.b2c-search-button-red {
		width: 100%;
	}
	.w-50 {
    width: 100% !important;
  }
	.container-body {
		display: block !important;
	}
	.mx-100 {
		max-width: 100% !important;
	}
	.container-text {
		margin-left: 0.5rem !important;
	}
	.content-mt {
		margin-top: 40% !important;
	}
  .content-mt-mb__Active{
    margin-top: 60% !important;
  }
	.content-body {
		right: 105%;
		top: 50%;
	}
  .content-body-mb__Active{
    top:45%;
  }
	.btn {
    margin-left: auto;
    margin-right: auto;
		margin-bottom: 50px;
	}
	.b2c-pc-header h2 {
		font-size: 16px;
		padding-left: 0;
	}
	.mx-datepicker {
    width: 100%;
    margin-left: 0 !important;
	}
	.mxt-0 {
		margin-top: 0 !important;
	}
	.mxl-0 {
		margin-left: -11px !important;
	}
	.myl-0 {
		margin-left: 0 !important;
	}
	.pxl-0 {
		padding-left: 0 !important;
	}
	.card {
    display: block;
	}
	.inputText {
    font-size: 16px;
	}
	.btn-w {
		width: 150px;
	}
	.success-body {
		padding: 0 !important;
	}

}
</style>

<style>
.form-control {
	font-size: 16px !important;
	font-weight: 400 !important;
}
.pre-scrollable {
  max-height: 300px;
  overflow-y: scroll;
}
.mx-input:disabled,
.form-control:disabled {
	color: #000000;
}
button.vs__clear {
  display: none;
}
.modal-header .close {
    display: none;
}

.modal .modal-content {
	width: 752px;
	border-radius: 10px;
}
.modal.fade .modal-dialog {
  margin-left: 560px;
}
.custom-control-input[disabled] ~
.custom-control-label::before,
.custom-control-input:disabled ~
.custom-control-label::before {
	background-color: #fff;
	border: 1px solid #d8d6de !important;
}
.custom-checkbox
.custom-control-input:disabled:checked ~
.custom-control-label:before {
	background-color: #ff0006;
}
.mx-input {
	padding-left: 20px;
	height: 50px;
	border-radius: 10px;
}
.vs--searchable .vs__dropdown-toggle {
	padding: 0 20px 0;
	height: 50px !important;
	border-radius: 10px !important;
}
.custom-checkbox .custom-control-label:before {
    border-radius: 50%;
}
@media screen and (max-width: 1440px) {
    .modal.fade .modal-dialog {
        margin-left: 250px;
    }
}
@media screen and (max-width: 820px) {
    .modal.fade .modal-dialog {
        margin: 0.8rem;
    }
}
@media screen and (max-width: 450px) {
	.modal .modal-content {
	width: 90%;
	margin: 0 auto;
	}
	.line {
		width: 90%;
	}
}
</style>
